import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import CurrencyFilter from '@/filters/currency.filter';
import { PROJECT_QUOTES_STORE } from '@/store/modules/project-quotes';
export default defineComponent({
    name: 'tp-confirm-agreement-modal',
    emits: ['cancel-action', 'confirm-accept-project-job'],
    components: {
        BaseModal,
    },
    props: {
        show: Boolean,
        loading: Boolean,
        project: Object,
        projectQuote: Object
    },
    computed: {
        information() {
            const filter = CurrencyFilter;
            const { project, projectQuote } = this;
            const { name: projectName, refId: projectId, projectCreator, postcode: projectAddress } = project;
            const { projectQuoteCreator, projectStages } = projectQuote;
            const { address: tpAddress, userBusinessDetails } = projectQuoteCreator;
            const { businessName, companyName } = userBusinessDetails;
            const stages = projectStages.map((projectStage) => {
                const { name, serviceFee, stageCost } = projectStage;
                const rawTotalCost = stageCost + serviceFee;
                const totalCost = filter.formatToCurrency(rawTotalCost);
                const stageName = name || 'Materials';
                return {
                    name: stageName,
                    totalCost,
                    rawTotalCost
                };
            });
            const totalCostPerStage = stages.map((stage) => stage.rawTotalCost);
            const totalCostSumOfStages = totalCostPerStage.reduce((accumulator, a) => {
                return accumulator + a;
            }, 0);
            return {
                projectName,
                projectId,
                clientName: `${projectCreator.firstName} ${projectCreator.lastName}`,
                projectAddress,
                tpCompanyName: (companyName || businessName) || `${projectQuoteCreator.firstName} ${projectQuoteCreator.lastName}`,
                tpAddress,
                stages,
                totalCostSumOfStages: filter.formatToCurrency(totalCostSumOfStages)
            };
        }
    },
    async created() {
        await this.setProjectStageServiceFee();
    },
    methods: {
        ...mapActions(PROJECT_QUOTES_STORE, ['getTotalStageCost', 'calculateServiceFee']),
        accept() {
            this.$emit('confirm-accept-project-job');
        },
        cancel() {
            this.$emit('cancel-action');
        },
        async setProjectStageServiceFee() {
            const { projectQuote } = this;
            if (projectQuote.projectStages) {
                const getProjectStageServiceFeePromises = [];
                projectQuote.projectStages.map((projectStage, i) => {
                    getProjectStageServiceFeePromises.push(this.getProjectStageServiceFee(i));
                    return projectStage;
                });
                const getProjectStageServiceFeeResponses = await Promise.all(getProjectStageServiceFeePromises);
                if (getProjectStageServiceFeeResponses) {
                    projectQuote.projectStages = projectQuote.projectStages.map((projectStage, i) => {
                        const projectStageWithServiceFee = { ...projectStage, serviceFee: getProjectStageServiceFeeResponses[i] };
                        return projectStageWithServiceFee;
                    });
                }
            }
        },
        async getProjectStageServiceFee(stageIndex) {
            const { projectQuote } = this;
            const totalStageCost = await this.getTotalStageCost(projectQuote.projectStages);
            const totalServiceFee = await this.calculateServiceFee({ projectId: this.selectedProject?.id, projectStages: projectQuote.projectStages });
            const stageCostPercentage = projectQuote.projectStages[stageIndex].stageCost / totalStageCost;
            const stageServiceFee = totalServiceFee * stageCostPercentage;
            return parseFloat(stageServiceFee.toFixed(2));
        }
    }
});
