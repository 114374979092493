import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { ProjectJobStatusEnum, ProjectQuoteStatusEnum, ProjectStageStatusEnum, ProjectStageTypeEnum, RolesEnum } from '@/core/packages/shared-library';
import TpConfirmAgreementModal from '@/modules/project-overview/modals/TpConfirmAgreementModal.vue';
import { PROJECT_QUOTES_STORE } from '../../../../store/modules/project-quotes';
export default defineComponent({
    name: 'project-quote-summary-card',
    components: {
        TpConfirmAgreementModal
    },
    props: {
        user: Object,
        quoteRecord: Object,
        project: Object,
        projectJobStatus: Object,
        projectId: Number,
        canCancel: Boolean,
        canEditOrSubmitQuote: Boolean,
        totalStageAmount: Number,
        submitting: Boolean,
    },
    emits: ['confirm-quote-submit', 'confirm-quote-cancel', 'confirm-update-quote', 'confirm-accept-quote', 'confirm-reject-quote'],
    data() {
        return {
            ProjectJobStatusEnum,
            ProjectStageStatusEnum,
            ProjectQuoteStatusEnum,
            ProjectStageTypeEnum,
            RolesEnum,
            totalStageCost: 0,
            serviceFee: 0,
            customerPays: { total: 0, serviceFee: 0 },
            thresholdPercentage: 10,
            openConfirmAgreementModal: false,
            agreement: false
        };
    },
    watch: {
        totalStageAmount: {
            immediate: true,
            handler() {
                if (this.quoteRecord != null) {
                    this.getTotalStageCostFn();
                    this.getCustomerTotalPaymentCharges();
                }
                return true;
            }
        },
    },
    async created() {
        await this.getTotalStageCostFn();
        await this.getCustomerTotalPaymentCharges();
    },
    computed: {
        ...mapGetters(['isTradesperson', 'isProjectOwner']),
        ...mapGetters(PROJECT_QUOTES_STORE, ['getProjectQuoteSummary']),
        getQuoteStages() {
            const stages = this.quoteRecord.projectStages;
            let result = [];
            // Stage(Labour) first
            result = stages.filter((stage) => stage.stageType === ProjectStageTypeEnum.LABOUR);
            // Stage(Materials) last
            result = result.concat(stages.filter((stage) => stage.stageType === ProjectStageTypeEnum.MATERIALS));
            return result;
        },
        userRole() {
            return this.user.role.id;
        },
        canEditQuote() {
            return this.quoteRecord.id && this.quoteRecord.projectCreator?.id === this.user.id;
        }
    },
    methods: {
        ...mapActions(PROJECT_QUOTES_STORE, ['getTotalStageCost', 'getCustomerPay']),
        getStageCostWithServiceFee(payload) {
            const { stageCost } = payload.stage;
            const stageCostPercentage = stageCost / this.totalStageCost;
            const stageServiceFee = this.customerPays.serviceFee * stageCostPercentage;
            const stageCostWithServiceFee = parseFloat(payload.stage.stageCost + stageServiceFee);
            return !Number.isNaN(stageCostWithServiceFee) ? stageCostWithServiceFee : 0;
        },
        async getTotalStageCostFn() {
            this.totalStageCost = await this.getTotalStageCost(this.quoteRecord.projectStages);
        },
        async getCustomerTotalPaymentCharges() {
            this.customerPays = await this.getCustomerPay({ projectId: this.projectId, quote: this.quoteRecord });
        },
        confirmUpdateQuote() {
            this.$emit('confirm-update-quote', this.quoteRecord);
        },
        confirmCancelQuote() {
            this.$emit('confirm-quote-cancel', this.quoteRecord);
        },
        confirmSubmitQuote() {
            this.$emit('confirm-quote-submit', this.quoteRecord);
        },
        onCancelAgreement() {
            this.openConfirmAgreementModal = false;
        },
        onAgreeTerms() {
            this.openConfirmAgreementModal = true;
        },
        onConfirmQuoteAccept() {
            this.agreement = true;
            this.$emit('confirm-accept-quote');
        }
    }
});
